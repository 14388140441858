
import { defineComponent } from "vue";
import PageButtonsComponent from "@/components/PageButtons.component.vue";
import AlertFormComponent from "@/components/alerts/AlertForm.component.vue";
import AlertListComponent from "@/components/alerts/AlertList.component.vue";
import { Alert } from "@/types/alert.type";
import { ElMessage } from "element-plus";

export default defineComponent({
  components: {
    PageButtonsComponent,
    AlertFormComponent,
    AlertListComponent,
  },
  data() {
    return {
      currentView: 1,
      showMap: false,
      showAlertForm: false,
      alertForm: undefined as undefined | Alert,
      alertsList: [],
    };
  },
  methods: {
    goToNextView() {
      this.currentView++;
    },
    goToPreviousView() {
      this.currentView--;
    },
    openAlertFormDialog() {
      this.currentView = 1;
      this.showAlertForm = true;
    },
    closeAlertFormDialog() {
      this.showAlertForm = false;
      this.currentView = 1;
    },
    saveAlertForm() {
      (this.$refs["alertForm"] as InstanceType<typeof AlertFormComponent>)
        .doSave()
        ?.then(() => {
          this.closeAlertFormDialog();
          (this.$refs["alertList"] as InstanceType<typeof AlertListComponent>).refreshAlert();
          ElMessage.success(this.$t("success.action"));
        })
        .catch(() => void 0);
    },
  },
});
