import { Alert, AlertType } from "./../types/alert.type";
import { Filter } from "./../types/filter.type";
import { Pagination } from "./../types/pagination.type";
import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";

export const getAlertPage = (
  page: number | undefined,
  size: number | undefined,
  filter?: Filter
): Promise<Pagination<Alert>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }

  return axios.get(`/alert`, { params }).then(responseHandlerDefault);
};

export const getAlertById = (alertId: number): Promise<Alert> => {
  return axios.get(`/alert/${alertId}`).then(responseHandlerDefault);
};

// export const postAlert = (alert: Alert): Promise<Alert> => {
//   return axios.post(`/alert`, alert).then(responseHandlerDefault);
// };
export const postAlert = (alert: Alert): Promise<Alert> => {
  return axios
    .post(`/alert/new`, alert)
    .then(responseHandlerDefault)
    .catch((error) => {
      console.error("Error en postAlert:", error);
      throw error;
    });
};

export const changeAlertActive = (alertId: number, active: boolean): Promise<Alert> => {
  if (active) {
    return axios.put(`/alert/${alertId}/activate`).then(responseHandlerDefault);
  } else {
    return axios.put(`/alert/${alertId}/deactivate`).then(responseHandlerDefault);
  }
};
export const putAlert = (alertId: number, alert: Alert): Promise<Alert> => {
  return axios.put(`/alert/${alertId}`, alert).then(responseHandlerDefault);
};

export const deleteParameterAlert = (alert: AlertType[]): Promise<void> => {
  return axios.post(`/alert/remove/`, alert).then(responseHandlerDefault);
};

export const deleteAlert = (alert: Alert): Promise<JSON> => {
  return axios.post(`/alert/remove/${alert.id}`).then(responseHandlerDefault);
};
