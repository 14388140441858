import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.alertFormList,
    "label-position": "top",
    ref: "form"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, { gutter: 12 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { lg: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "samplingPoint",
                label: _ctx.$t('label.monitorizationPoint')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.alertFormList.samplingPoint,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alertFormList.samplingPoint) = $event)),
                    "value-key": "id"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.samplingPoints, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "parameter",
                label: _ctx.$t('label.parameter')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.alertFormList.parameter,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.alertFormList.parameter) = $event)),
                    "value-key": "id"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parameters, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "operator",
                label: _ctx.$t('label.operator')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.alertFormList.operator,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.alertFormList.operator) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operators, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.name,
                          label: item.name,
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 4 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "value",
                label: _ctx.$t('label.value')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.alertFormList.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.alertFormList.value) = $event)),
                    modelModifiers: { number: true },
                    type: "number"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { lg: 4 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('label.andOr')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.alertFormList.compositeOperator,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.alertFormList.compositeOperator) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logicOper, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item,
                          label: item,
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}