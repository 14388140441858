
import { defineComponent } from "@vue/runtime-core";
import { Parameter } from "@/types/parameter.type";
import { AlertType } from "@/types/alert.type";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { getParameter } from "@/service/parameter.service";
import { getSamplingPoints, getSamplingPointsNoRelations } from "@/service/samplingpoint.service";
import { Filter } from "@/types/filter.type";

export default defineComponent({
  components: {},
  props: {
    id: {
      type: Number,
      required: false,
    },
    item: {
      type: Object as () => AlertType,
      required: true,
    },
  },
  data() {
    return {
      samplingPoints: [] as SamplingPoint[],
      parameters: [] as Parameter[],
      alertFormList: {} as AlertType,

      operators: [">", "<", "="],
      logicOper: ["AND", "OR"],
    };
  },
  async mounted() {
    const sampResponse = await getSamplingPointsNoRelations();
    this.samplingPoints = sampResponse.data;
    const filter: Filter = {
      fields: {
        "parameter.active": { operator: "equal", value: true },
        "parameter.client": { operator: "equal", value: false },
        "parameter.type": { operator: "equal", value: "SAMPLINGPOINT" },
      },
    };
    const parametersPagination = await getParameter(undefined, undefined, filter);
    this.parameters = parametersPagination.data;
    this.alertFormList = Object.assign({}, this.item);
  },
  methods: {
    async doSave() {
      return this.alertFormList;
    },
  },
});
