
import { getParameter } from "@/service/parameter.service";
import { getSamplingPoints, getSamplingPointsNoRelations } from "@/service/samplingpoint.service";
import { deleteParameterAlert, getAlertById, postAlert, putAlert } from "@/service/alert.service";
import AlertEditFormComponent from "@/components/alerts/AlertEditForm.component.vue";
import { Alert, AlertType } from "@/types/alert.type";
import { AppError } from "@/types/app.type";
import { Parameter } from "@/types/parameter.type";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { defineComponent } from "@vue/runtime-core";
import { ElForm, ElMessage } from "element-plus";
import { Filter } from "@/types/filter.type";
import { createLogger } from "vuex";
import { getSamPointOnlyParameters } from "@/service/samplingpoint.service";

const errorMessage = "error.required";

interface AlertRow {
  value: number | string;
  status: boolean;
}

export default defineComponent({
  components: { AlertEditFormComponent },
  props: {
    id: {
      type: Number,
      required: false,
    },
    currentView: {
      type: Number,
      required: false,
    },
  },
  setup: () => {
    const ruleDisplayOrOpened = false;
    return { ruleDisplayOrOpened };
  },
  data() {
    return {
      newEmail: "",
      generatedString: "",
      triggers: {
        eventTriggered: false,
        eventStartEnd: true,
      },
      alertForm: {} as Alert,
      alertFormList: {} as AlertType,
      alertList: [] as AlertType[],
      newAlertList: [] as AlertType[],
      removeAlertList: [] as AlertType[],
      alertArray: [] as Alert[],
      samplingPoints: [] as SamplingPoint[],
      parameterList: [] as Parameter[],
      typeAlert: "absolute",
      operList: [">", "<", "="],
      operRelative: ["↑", "↓"],
      compOperList: ["AND", "OR"],
      // selectedAnd: false as boolean,
      // selectedOr: false as boolean,
      editAlert: false as boolean,
      editAlertForm: undefined as undefined | AlertType,
      item: {} as AlertType,
      rules1: {
        samplingPoint: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
        parameter: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
        value: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
        operator: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
      },
      rules2: {
        name: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
      },
    };
  },
  async mounted() {
    this.alertFormList.compositeOperator = "AND";
    this.alertForm.emails = [];
    const response = await getSamplingPointsNoRelations();
    this.samplingPoints = response.data;
    const filter: Filter = {
      fields: {
        "parameter.active": { operator: "equal", value: true },
        "parameter.client": { operator: "equal", value: false },
        "parameter.type": { operator: "equal", value: "SAMPLINGPOINT" },
      },
    };
    // const parametersPagination = await getParameter(undefined, undefined, filter);
    // this.parameterList = parametersPagination.data;

    if (this.id) {
      this.alertForm = await getAlertById(this.id);
      if (this.alertForm.triggers) {
        this.triggers.eventStartEnd = true;
        this.triggers.eventTriggered = false;
      } else {
        this.triggers.eventStartEnd = false;
        this.triggers.eventTriggered = true;
      }
      if (this.alertForm.parameterAlerts) {
        if (
          this.alertForm?.parameterAlerts[0]?.operator === "↑" ||
          this.alertForm?.parameterAlerts[0]?.operator === "↓"
        ) {
          this.typeAlert = "relative";
        }
        this.alertList = [];
        this.alertList = this.alertForm.parameterAlerts;

        this.alertList.forEach((_) => {
          _.status = false;
        });
      }
    }
  },
  methods: {
    generateString() {
      let result = "";
      this.alertList.forEach((rule) => {
        result += this.ruleDisplayBeforeTag(rule.compositeOperator);
        result += `${rule.samplingPoint ? rule.samplingPoint.name : ""} `;
        result += `${rule.parameter ? rule.parameter.name : ""} ${rule.operator} ${rule.value}`;
        result += this.ruleDisplayAfterTag(rule.compositeOperator);
      });
      this.generatedString = result.trim();
    },
    addEmail() {
      if (this.newEmail && this.validateEmail(this.newEmail) && this.alertForm.emails) {
        this.alertForm.emails.push(this.newEmail.trim());
        this.newEmail = "";
      } else {
        ElMessage.warning("Por favor, ingresa un correo válido");
      }
    },
    removeEmail(index: number) {
      if (this.alertForm.emails) {
        this.alertForm.emails.splice(index, 1);
      }
    },
    validateEmail(email: string) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    validateInput(row: AlertRow) {
      if (row.value === null || row.value === "") {
        row.value = 0;
      }
    },
    handleCheckboxChange(changedTrigger: string) {
      if (changedTrigger === "eventTriggered") {
        if (!this.triggers.eventTriggered && !this.triggers.eventStartEnd) {
          this.triggers.eventTriggered = true;
        } else {
          this.triggers.eventStartEnd = false;
        }
      } else if (changedTrigger === "eventStartEnd") {
        if (!this.triggers.eventStartEnd && !this.triggers.eventTriggered) {
          this.triggers.eventStartEnd = true;
        } else {
          this.triggers.eventTriggered = false;
        }
      }
    },
    async saveAlert() {
      //Redondear el value antes de subir
      this.fixValue(this.alertFormList);

      let valid;
      try {
        valid = await (this.$refs["form1"] as InstanceType<typeof ElForm>).validate();
      } catch (error) {
        valid = false;
      }
      if (!valid) {
        throw new Error("validation");
      } else {
        const newAlert = {} as AlertType;
        //newAlert.id = this.alertList.length;
        newAlert.samplingPoint = this.alertFormList.samplingPoint;
        newAlert.parameter = this.alertFormList.parameter;
        newAlert.value = this.alertFormList.value;
        newAlert.operator = this.alertFormList.operator;
        //newAlert.alert = this.alertForm.id;
        newAlert.compositeOperator = this.alertFormList.compositeOperator;

        this.alertList.push(newAlert);
        this.newAlertList.push(newAlert);
        if (this.alertForm.id === undefined) {
          this.alertForm.parameterAlerts?.push(newAlert);
        }
      }
    },
    async doSave() {
      if (this.alertList.length === 0) {
        ElMessage.error(this.$t("error.NO_ALERTS"));
        throw new Error(AppError.FORM_VALIDATION);
      } else if (this.alertForm.name === undefined || this.alertForm.name.length === 0) {
        ElMessage.error(this.$t("error.no_name"));
        throw new Error(AppError.FORM_VALIDATION);
      }

      this.generateString();
      this.alertForm.conditionString = this.generatedString;
      this.alertForm.triggers = this.triggers.eventStartEnd;

      let response;

      this.alertForm.parameterAlerts = this.alertList;

      if (this.alertForm.id === undefined) {
        this.alertForm.emails = this.alertForm.emails ?? [];
        console.log("Formulario de alerta antes de enviar:", this.alertForm);
        // response = await postAlert(this.alertForm);
        // this.alertArray.push(response);
        try {
          response = await postAlert(this.alertForm);
          console.log("Respuesta después de guardar la alerta:", response);
          this.alertArray.push(response);
        } catch (error) {
          console.error("Error al intentar guardar la alerta:", error);
        }
      } else {
        // Si se ha borrado una condición que ya estaba creada
        if (this.removeAlertList.length > 0) {
          // await deleteParameterAlert(this.removeAlertList);
          await deleteParameterAlert(this.removeAlertList);
        }

        // Se guardan solo las nuevas condiciones
        this.alertForm.parameterAlerts = [];
        this.alertForm.parameterAlerts = this.newAlertList;
        response = await putAlert(this.alertForm.id, this.alertForm);

        this.alertArray.push(response);
      }

      return response;
    },
    deleteAlert(item: AlertType) {
      const i = this.alertList.indexOf(item);
      if (i !== -1) {
        this.alertList.splice(i, 1);
        this.removeAlertList.push(item);
      }

      const index = this.newAlertList.indexOf(item);
      if (index !== -1) {
        this.newAlertList.splice(index, 1);
      }
    },
    ruleDisplayBeforeTag(compositeOperator?: string) {
      if (compositeOperator === "OR" && !this.ruleDisplayOrOpened) {
        this.ruleDisplayOrOpened = true;
        return "(";
      } else {
        return "";
      }
    },
    ruleDisplayAfterTag(compositeOperator?: string) {
      let after = "";
      if (
        (compositeOperator === "AND" || compositeOperator === undefined) &&
        this.ruleDisplayOrOpened
      ) {
        after += ")";
        this.ruleDisplayOrOpened = false;
      }
      if (compositeOperator === "AND") {
        after += " Y ";
      } else if (compositeOperator === "OR") {
        after += " O ";
      }
      return after;
    },
    showEdit(item: AlertType) {
      item.status = true;
    },
    edit(item: AlertType) {
      item.status = false;
      this.fixValue(item);

      const i = this.alertList.findIndex((index) => index.id === item.id);
      this.newAlertList.splice(i, 1);
      this.newAlertList.push(item);
    },
    closeEditAlert() {
      this.editAlert = false;
      this.item = {} as AlertType;
    },
    async saveAlertEdit() {
      this.alertForm.triggers = this.triggers.eventStartEnd;

      this.generateString();
      this.alertForm.conditionString = this.generatedString;

      this.editAlertForm = await (
        this.$refs["editAlertForm"] as InstanceType<typeof AlertEditFormComponent>
      ).doSave();

      this.closeEditAlert();
      ElMessage.success(this.$t("success.action"));

      const index = this.alertList.findIndex((i) => i.id === this.editAlertForm?.id);
      if (index !== -1 && this.editAlertForm) {
        this.alertList[index] = this.editAlertForm;
      }
    },

    fixValue(item: AlertType) {
      if (item.value) {
        item.value = parseFloat(item.value.toFixed(2));
      }
    },

    changeType() {
      this.alertFormList.operator = undefined;
      this.alertFormList.alert = undefined;
      this.alertFormList.parameter = undefined;
      this.alertFormList.compositeOperator = "AND";
      this.alertFormList.samplingPoint = undefined;
      this.alertFormList.value = undefined;
      for (const alert of Object.assign([], this.alertList)) {
        this.deleteAlert(alert);
      }
      if (this.alertList.length > 0) {
        this.alertList = [];
      }
    },

    async putParameters(samPoint: SamplingPoint) {
      // if (!samPoint.hasAllParameters) {
      //   this.parameters = samPoint.parameters;
      // } else {

      this.alertFormList.parameter = {} as Parameter;

      let filter: Filter = {
        fields: {
          "parameter.active": { operator: "equal", value: true },
          "parameter.client": { operator: "equal", value: false },
          "parameter.type": { operator: "equal", value: "SAMPLINGPOINT" },
        },
        multi: undefined,
      };
      //const param = await getParameter(undefined, undefined, filter);
      if (samPoint.id) {
        const param = await getSamPointOnlyParameters(samPoint.id);

        this.parameterList = param;
      }
    },
  },
});
