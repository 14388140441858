<template>
  <div class="no-data">
    <p>{{ message }}</p>
    <p>{{ subMessage }}</p>
    <img v-if="imageSrc" :src="imageSrc" alt="WEAS_LOGO" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String,
      default: "No hay datos disponibles",
    },
    subMessage: {
      type: String,
      default: "",
    },
    imageSrc: {
      type: String,
      default: "/img/WEAS_LOGO.png",
    },
  },
});
</script>

<style scoped>
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  text-align: center;
  font-size: 20px;
  opacity: 0.3;
}

.no-data img {
  max-width: 100%;
}
</style>
