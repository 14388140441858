
import { Alert } from "@/types/alert.type";
import { defineComponent } from "@vue/runtime-core";
import { getSamplingPointNames } from "@/service/paramaeterAlert.service";

export default defineComponent({
  props: {
    item: {
      type: Object as () => Alert,
      required: true,
    },
  },
  data() {
    return {
      codePoints: [] as string[],
      total: 0 as number,
      parameterAlerts: [] as any[],
    };
  },
  mounted() {
    this.refreshParameter();
  },
  methods: {
    async refreshParameter() {
      this.parameterAlerts = await getSamplingPointNames(this.item);
    },

    showName(index: number, name: string) {
      let namePoint = name;
      if (namePoint.length > 15) {
        namePoint = `${name.substring(0, 15)}...`;
      }

      return index <= 1 ? namePoint : "+";
    },
  },
  watch: {
    item: function () {
      this.refreshParameter();
    },
  },
});
