
import { defineComponent } from "vue";
import { Alert, AlertType } from "@/types/alert.type";
import { Filter } from "@/types/filter.type";
import AlertFormComponent from "@/components/alerts/AlertForm.component.vue";
import SamplingPointTagComponent from "@/components/samplingPoints/SamplingPointTag.component.vue";
import { changeAlertActive, deleteAlert, getAlertPage } from "@/service/alert.service";
import { Action, ElMessage, ElMessageBox } from "element-plus";
import NoDataComponent from "@/components/NoData.component.vue";

const successAction = "success.action";
export default defineComponent({
  components: {
    // PageHeaderComponent,
    AlertFormComponent,
    SamplingPointTagComponent,
    NoDataComponent,
  },
  props: {
    detailRouterName: {
      type: String,
    },
    list: {
      type: Object as () => Alert[],
    },
  },
  data() {
    return {
      currentView: 1 as number,
      query: "" as string,
      alerts: [] as Alert[],
      hasData: true,
      showAlertForm: false,
      showAlertInfo: false,
      alertSelected: undefined as undefined | Alert,
      currentPage: 1 as number,
      rowPerPage: 10 as number,
      totalRows: 0 as number,
      showEntityForm: false,
    };
  },
  mounted() {
    this.refreshAlert();
  },
  methods: {
    goToNextView() {
      this.currentView++;
    },
    goToPreviousView() {
      this.currentView--;
    },
    closeAlertDialog() {
      this.currentView = 1;
      this.showAlertForm = false;
      this.showAlertInfo = false;
      this.alertSelected = undefined;
    },
    async refreshAlert(item: Alert | undefined = undefined) {
      let index = -1;
      if (this.alerts && item) {
        index = this.alerts.findIndex((i) => i.id === item.id);
      }
      if (index !== -1 && item) {
        this.alerts[index] = item;
      } else {
        const filter: Filter = {};
        if (this.query) {
          filter.multi = this.query;
        }
        const pagination = await getAlertPage(this.currentPage, this.rowPerPage, filter);
        this.alerts = pagination.data;
        this.totalRows = pagination.totalRows;
      }
      if (this.alerts.length <= 0) {
        this.hasData = false;
        return;
      } else {
        this.hasData = true;
      }
      return this.alerts;
    },
    changePaginationSize(size: number): void {
      this.rowPerPage = size;
      this.refreshAlert();
    },

    changePaginationPage(page: number): void {
      this.currentPage = page;
      this.refreshAlert();
    },

    async saveAlert(alertSelected: Alert | undefined) {
      // - call alertForm save method
      const alert = await (
        this.$refs["alertForm"] as InstanceType<typeof AlertFormComponent>
      ).doSave();

      // - close dialog and refresh
      this.closeAlertDialog();
      this.refreshAlert(alert);

      ElMessage.success(this.$t(successAction));
    },

    async deactivateAlert(item: Alert) {
      if (item.id && item.active !== undefined) {
        const typeMessage = item.active ? "activateAlert" : "deactivateAlert";
        ElMessageBox.confirm(
          this.$t("confirm." + typeMessage, { name: item.name }),
          this.$t("label.warning"),
          {
            confirmButtonText: this.$t("btn.accept"),
            cancelButtonText: this.$t("btn.cancel"),
            type: "warning",
            dangerouslyUseHTMLString: true,
            callback: (action: Action) => {
              if (action === "confirm" && item.id && item.active !== undefined) {
                changeAlertActive(item.id, item.active).then(() => {
                  ElMessage.success(this.$t(successAction));
                  this.refreshAlert();
                });
              } else {
                item.active = !item.active;
                this.refreshAlert();
              }
            },
          }
        );
      }
    },

    /**
     * open alert formDialog
     * @param item
     */
    openEntityFormDialog(item: Alert | undefined = undefined) {
      this.alertSelected = item;
      this.showAlertForm = true;
    },

    deleteAlert(item: Alert | undefined = undefined) {
      if (item && item.id) {
        ElMessageBox.confirm(
          this.$t("confirm.deleteAlert", { name: item.name }),
          this.$t("label.warning"),
          {
            confirmButtonText: this.$t("btn.accept"),
            cancelButtonText: this.$t("btn.cancel"),
            type: "warning",
            dangerouslyUseHTMLString: true,
            callback: (action: Action) => {
              if (action === "confirm") {
                deleteAlert(item).then(() => {
                  ElMessage.success(this.$t(successAction));
                  this.refreshAlert();
                });
              }
            },
          }
        );
      }
    },
  },
});
